import Vue from "vue";

function addVueFilters() {
  // Example filter from https://vuejs.org/v2/guide/filters.html
  Vue.filter("capitalize", function(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  Vue.filter("termini", function({ tpq, taq }) {
    return (tpq || "?") + " — " + (taq || "?");
  });
}

export default addVueFilters;
