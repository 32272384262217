import Vue from "vue";
import Router from "vue-router";

const ArtefactDetailPage = () => import("./pages/artefacts/detail");
const ArtefactsPage = () => import("./pages/artefacts");
const BracteareTypeDetailPage = () => import("./pages/bracteates/detail");
const BracteateTypesPage = () => import("./pages/bracteates");
const CarversPage = () => import("./pages/carvers");
const CarverDetailPage = () => import("./pages/carvers/detail");
const CountiesPage = () => import("./pages/counties");
const CountriesPage = () => import("./pages/countries");
const CountyDetailPage = () => import("./pages/counties/detail");
const CountryDetailPage = () => import("./pages/countries/detail");
const CreateUser = () => import("./pages/add_user");
const CrossFormDetailPage = () => import("./pages/cross_forms/detail");
const CrossFormsPage = () => import("./pages/cross_forms");
const DivisionDetailPage = () => import("./pages/divisions/detail");
const DivisionsPage = () => import("./pages/divisions");
const GroupsPage = () => import("./pages/groups");
const GroupsDetailPage = () => import("./pages/groups/detail");
const HundredsDetailPage = () => import("./pages/hundreds/detail");
const HundredsPage = () => import("./pages/hundreds");
const InscriptionPage = () => import("./pages/inscriptions/detail");
const InscriptionsPage = () => import("./pages/inscriptions");
const LanguagesPage = () => import("./pages/languages");
const LanguageDetailPage = () => import("./pages/languages/detail");
const LoginPage = () => import("./pages/login");
const MaterialDetailPage = () => import("./pages/materials/detail");
const MaterialsPage = () => import("./pages/materials");
const MaterialSubTypeDetailPage = () =>
  import("./pages/material_sub_types/detail");
const MaterialSubTypesPage = () => import("./pages/material_sub_types");
const MaterialTypeDetailPage = () => import("./pages/material_types/detail");
const MaterialTypesPage = () => import("./pages/material_types");
const MunicipalitiesPage = () => import("./pages/municipalities");
const MunicipalityDetailPage = () => import("./pages/municipalities/detail");
const ParishDetailPage = () => import("./pages/parishes/detail");
const ParishesPage = () => import("./pages/parishes");
const PhysicalObjectDetailPage = () =>
  import("./pages/physical_objects/detail");
const PhysicalObjectsPage = () => import("./pages/physical_objects");
const PlaceDetailPage = () => import("./pages/places/detail");
const PlacesPage = () => import("./pages/places");
const ProvinceDetailPage = () => import("./pages/provinces/detail");
const ProvincesPage = () => import("./pages/provinces");
const RuneTypesPage = () => import("./pages/rune_types");
const RuneTypeDetailPage = () => import("./pages/rune_types/detail");
const Signa1Page = () => import("./pages/signa1");
const Signum1DetailPage = () => import("./pages/signa1/detail");
const SignaPage = () => import("./pages/signa");
const SignumDetailPage = () => import("./pages/signa/detail");
const SourcesPage = () => import("./pages/sources");
const SourceDetailPage = () => import("./pages/sources/detail");
const StyleDetailPage = () => import("./pages/styles/detail");
const StylesPage = () => import("./pages/styles");
const PeriodDetailPage = () => import("./pages/periods/detail");
const PeriodsPage = () => import("./pages/periods");
const StartPage = () => import("./pages/start");
const URISPage = () => import("./pages/uris");
const URIDetailPage = () => import("./pages/uris/detail");
const HerPage = () => import("./pages/her");
const HerSwedenPage = () => import("./pages/her/herSweden");
const HerSwedenDetailPage = () => import("./pages/her/herSweden/detail");
const HerNorwayPage = () => import("./pages/her/herNorway");
const HerNorwayDetailPage = () => import("./pages/her/herNorway/detail");
const HerDenmarkPage = () => import("./pages/her/herDenmark");
const HerDenmarkDetailPage = () => import("./pages/her/herDenmark/detail");
const HerGreatBritainPage = () => import("./pages/her/herGreatBritain");
const HerGreatBritainDetailPage = () =>
  import("./pages/her/herGreatBritain/detail");
const HerSwedenParishPage = () => import("./pages/her/herSwedenParish");
const HerSwedenParishDetailPage = () =>
  import("./pages/her/herSwedenParish/detail");

const NotImplementedPage = () => import("./pages/NotImplementedPage.vue");

Vue.use(Router);

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  /*

  scrollBehavior: (to, from, savedPosition) =>
    new Promise(resolve => {
      console.log("1");
      const position = savedPosition || {};
      if (!savedPosition) {
        console.log("2");
        if (to.hash) {
          position.selector = to.hash;
        } else if (to.params.selector) {
          console.log("2.5");

          position.selector = to.params.selector;
          position.offset = { x: 0, y: -to.params.distanceFromTop };
        }

        if (to.matched.some(m => m.meta.scrollToTop)) {
          console.log("3");

          position.x = 0;
          position.y = 0;
        }
      }
      console.log("4", position);
      router.app.$root.$once("triggerScroll", pos => {
        console.log("5", pos);
        router.app.$nextTick(() =>
          setTimeout(() => {
            console.log("6", pos);
            resolve(pos);
          }, 1000)
        );
        console.log("7");
      });
    }),
  scrollBehavior(to, from, savedPosition) {
    if (!to.params.noScroll) {
      let scrollResolver = () => {
        if (savedPosition) {
          return savedPosition;
        } else if (to.params.selector) {
          // params.selector is not a standard param, it's something we added to be able to scroll to specific
          // positions in the page without polluting the hash (as to.hash does).
          // Sending unused params may not be supported by the Vue router, but it works for now.
          return {
            selector: to.params.selector
          };
        } else {
          return { x: 0, y: 0 };
        }
      };

      // Use a timeout since some pages take a while to load.
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(scrollResolver());
        }, 500);
      });
    }
  },
  */
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginPage
    },
    {
      path: "/",
      name: "home",
      component: StartPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/inscriptions",
      name: "inscriptions",
      component: InscriptionsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/carvers",
      name: "carvers",
      component: CarversPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/carvers/:id",
      name: "carver",
      component: CarverDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/languages",
      name: "languages",
      component: LanguagesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/languages/:id",
      name: "language",
      component: LanguageDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/signa1",
      name: "signa1",
      component: Signa1Page,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/objects",
      name: "objects",
      component: PhysicalObjectsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/objects/:id",
      name: "object",
      component: PhysicalObjectDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/groups",
      name: "groups",
      component: GroupsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/groups/:id",
      name: "group",
      component: GroupsDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/artefacts",
      name: "artefacts",
      component: ArtefactsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bracteates",
      name: "bracteates",
      component: BracteateTypesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/crossForms",
      name: "crossForms",
      component: CrossFormsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/styles",
      name: "styles",
      component: StylesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/periods",
      name: "periods",
      component: PeriodsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materials",
      name: "materials",
      component: MaterialsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materialTypes",
      name: "materialTypes",
      component: MaterialTypesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materialSubTypes",
      name: "materialSubTypes",
      component: MaterialSubTypesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/styles/:id",
      name: "style",
      component: StyleDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/periods/:id",
      name: "period",
      component: PeriodDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materials/:id",
      name: "material",
      component: MaterialDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/artefacts/:id",
      name: "artefact",
      component: ArtefactDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materialTypes/:id",
      name: "materialType",
      component: MaterialTypeDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/materialSubTypes/:id",
      name: "materialSubType",
      component: MaterialSubTypeDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bracteates/:id",
      name: "bracteate",
      component: BracteareTypeDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/crossforms/:id",
      name: "crossform",
      component: CrossFormDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sources",
      name: "sources",
      component: SourcesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sources/:id",
      name: "source",
      component: SourceDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/uris",
      name: "uris",
      component: URISPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/uris/:id",
      name: "uri",
      component: URIDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/inscriptions/:id",
      name: "inscription",
      component: InscriptionPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/signa",
      name: "signa",
      component: SignaPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/signa/:id",
      name: "signum",
      component: SignumDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/runeTypes",
      name: "runeTypes",
      component: RuneTypesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/runeTypes/:id",
      name: "runeType",
      component: RuneTypeDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/add-user",
      name: "addUser",
      component: CreateUser,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/parishes",
      name: "parishes",
      component: ParishesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/hundreds",
      name: "hundreds",
      component: HundredsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/municipalities",
      name: "municipalities",
      component: MunicipalitiesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/divisions",
      name: "divisions",
      component: DivisionsPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/places",
      name: "places",
      component: PlacesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/counties",
      name: "counties",
      component: CountiesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/countries",
      name: "countries",
      component: CountriesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/provinces",
      name: "provinces",
      component: ProvincesPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/places/:id",
      name: "place",
      component: PlaceDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/countries/:id",
      name: "country",
      component: CountryDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/counties/:id",
      name: "county",
      component: CountyDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/municipalities/:id",
      name: "municipality",
      component: MunicipalityDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/provinces/:id",
      name: "province",
      component: ProvinceDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/hundreds/:id",
      name: "hundred",
      component: HundredsDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/parishes/:id",
      name: "parish",
      component: ParishDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/divisions/:id",
      name: "division",
      component: DivisionDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/signa1/:id",
      name: "signum1",
      component: Signum1DetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/her",
      name: "her",
      component: HerPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herSweden",
      name: "herSweden",
      component: HerSwedenPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herSweden/:id",
      name: "herSwedenDetail",
      component: HerSwedenDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herNorway",
      name: "herNorway",
      component: HerNorwayPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herNorway/:id",
      name: "herNorwayDetail",
      component: HerNorwayDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herDenmark",
      name: "herDenmark",
      component: HerDenmarkPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herDenmark/:id",
      name: "herDenmarkDetail",
      component: HerDenmarkDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herGreatBritain",
      name: "herGreatBritain",
      component: HerGreatBritainPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herGreatBritain/:id",
      name: "herGreatBritainDetail",
      component: HerGreatBritainDetailPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herSwedenParishes",
      name: "herSwedenParishes",
      component: HerSwedenParishPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/herSwedenParishes/:id",
      name: "herSwedenParishDetail",
      component: HerSwedenParishDetailPage,
      meta: {
        requiresAuth: true
      }
    }
  ]
});
