// Standard Vue
import Vue from "vue";
import router from "./router";

// Third party libraries and CSSes
import axios from "axios";
import VueAxios from "vue-axios";
import { createProvider } from "./vue-apollo";
import { onLogin } from "./vue-apollo";
import { onLogout } from "./vue-apollo";
import BootstrapVue from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "regenerator-runtime/runtime";

import "vue-multiselect/dist/vue-multiselect.min.css";

import CKEditor from "@ckeditor/ckeditor5-vue";
import Meta from "vue-meta";

import i18n from "./i18n";

import addVueFilters from "./filters.js";

import gql from "graphql-tag";
import UniqueId from "vue-unique-id";

// Our main component
import App from "./App.vue";

// Our main CSS
import "./css/global.scss";

// Install third party components and libraries globally
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.component("multiselect", Multiselect);
Vue.use(CKEditor);
Vue.use(Meta);
Vue.use(UniqueId);

// Add global filters
addVueFilters();

// Set up Vue
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

router.redirectTo = null;

// Provide secure paths filter
router.beforeEach((to, from, next) => {
  // If logged in, skip authorization requirement
  const loginRequired = to.matched.some(rec => rec.meta.requiresAuth);
  if (loginRequired) {
    isLoggedIn(router.app)
      .then(response => {
        if (response.valid || response.data.data.isTokenValid) {
          router.app.validToken = true;
          next();
        } else {
          throw "Server responded with negative validation";
        }
      })
      .catch(() => {
        router.redirectTo = to;
        next("/login");
      });
  } else {
    next();
  }

  async function isLoggedIn(vueApp) {
    let hasLocalUserData = !(
      vueApp.user === undefined ||
      vueApp.user.username === undefined ||
      vueApp.user.username === ""
    );
    if (hasLocalUserData) {
      //Return new promise with successful result!
      return { valid: true };
    } else {
      return isTokenValid();
    }
  }

  /**
   * @returns {Promise}
   */
  async function isTokenValid() {
    let token = localStorage.getItem("apollo-token");
    if (!token) {
      throw "No token exists";
    }
    return isTokenAuthenticated(token);
  }

  /**
   * Validates the token with backend
   * @param token
   * @returns {Promise}
   */
  async function isTokenAuthenticated(token) {
    let GQL_ENDPOINT =
      process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:8080/gql-service";

    return axios({
      url: GQL_ENDPOINT,
      method: "POST",
      headers: { Authorization: token },
      data: {
        query: `
        query isLoggedIn {
          isTokenValid
        }`
      }
    });
  }
});

// Launch Vue
new Vue({
  router,
  apolloProvider: createProvider(),
  axios,
  i18n,
  data() {
    return {
      user: undefined,
      validToken: undefined
    };
  },
  created() {
    let $this = this;

    this.$eventBus.$on("login-action", function(data) {
      onLogin($this.$apolloProvider.defaultClient, "Bearer " + data.token).then(
        () => {
          $this.validToken = true;
          if ($this.$apollo && $this.$apollo.queries.user) {
            $this.$apollo.queries.user.refresh();
          }
          //Navigate to where we were before intercepted to login page
          let router = $this.$router;
          if (router.currentRoute.name === "login") {
            if (router.redirectTo) {
              let path = router.redirectTo.fullPath;
              router.redirectTo = null;
              router.push(path);
            } else {
              router.push("/");
            }
          }
        }
      );
    });

    this.$eventBus.$on("logout-action", function() {
      $this.user = undefined;
      $this.validToken = false;
      // noinspection JSUnresolvedVariable
      onLogout($this.$apolloProvider.defaultClient);
      // noinspection JSUnresolvedVariable
      /*
      if ($this.$router.currentRoute.name === "home") {
        //Handling the case where currentRoute is the same we wish to re-route to. (If no reload then cache will be used)
        $this.$router.go();
      } else {
        $this.$router.push("/");
      }
      */
      $this.$router.push("/login");
    });

    this.$eventBus.$on("no-connection", function() {
      //NavigationUser polling noticed an error
      $this.user = undefined;
      $this.validToken = false;
    });

    router.afterEach(() => {
      this.$eventBus.$emit("route-changed");
    });
  },
  apollo: {
    user: {
      query: gql`
        {
          user: whoami {
            firstname
            lastname
            email
            username
            roles
          }
        }
      `,
      pollInterval: 20000,
      skip() {
        return !this.validToken;
      },
      error: function() {
        this.user = undefined;
        this.validToken = false;
        return true;
      }
    }
  },
  render(h) {
    return h(App, { props: { user: this.user } });
  }
}).$mount("#app");

// Hot loading (https://kazupon.github.io/vue-i18n/guide/hot-reload.html)
if (module.hot) {
  // Reload when language texts change
  module.hot.accept(["./resources/locales/se"], function() {
    i18n.setLocaleMessage("se", require("./resources/locales/se").default);
  });
}
