<template>
  <div class="wrapper">
    <b-container fluid class="header-toolbar is-bg-dark position-fixed">
      <b-row align-h="between">
        <b-col class="position-absolute skip-buttons" cols="auto">
          <b-link
            href="#maincontent"
            class="sr-only sr-only-focusable btn btn-primary"
          >
            {{ $t("general.toMainContent") }}
          </b-link>
        </b-col>
        <b-col>
          <b-button
            :class="!showMobileArea ? 'collapsed' : null"
            :aria-expanded="showMobileArea ? 'true' : 'false'"
            aria-controls="area area-mobile"
            class="btn-icon icon-menu toggle-menu-btn d-sm-block d-md-none"
            @click="showMobileArea = !showMobileArea"
          >
            {{ $t("general.menu") }}</b-button
          >
          <b-button
            :class="!showDesktopArea ? 'collapsed' : null"
            :aria-expanded="showDesktopArea ? 'true' : 'false'"
            aria-controls="area-desktop "
            class="btn-icon icon-menu toggle-menu-btn d-none d-md-block"
            @click="showDesktopArea = !showDesktopArea"
          >
            {{ $t("general.menu") }}</b-button
          >
        </b-col>
        <b-col cols="auto"> <NavigationUser :user="user" /> </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <NavigationArea
          id="area-desktop"
          :show="showDesktopArea"
          class="d-none d-md-block "
        />
        <NavigationArea
          id="area-mobile"
          :show="showMobileArea"
          class="d-sm-block d-md-none"
        />

        <b-col class="pt-4 mt-5 mb-5 overflow-hidden">
          <b-alert
            :show="
              this.$root.validToken != undefined &&
                !this.$root.validToken &&
                this.$root.$route.name !== 'login'
            "
            variant="danger"
            >{{ $t("general.loggedOut") }}</b-alert
          >
          <main id="maincontent"><router-view /></main>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {
    NavigationArea: () => import("@/components/NavigationArea"),
    NavigationUser: () => import("@/components/NavigationUser")
  },
  props: {
    user: {
      type: Object,
      default: undefined
    }
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("general.siteName"),
      // all other titles will be injected into this template
      titleTemplate(title) {
        return title && title !== this.$t("general.siteName")
          ? `${title} - ${this.$t("general.siteName")}`
          : this.$t("general.siteName");
      }
    };
  },
  data: function() {
    return {
      showMobileArea: false,
      showDesktopArea: true
    };
  },
  created() {
    let self = this;
    this.$eventBus.$on("route-changed", function() {
      self.showMobileArea = false;
    });
  }
};
</script>
